import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";
import { ErrorCode, ErrorMessage } from "@/constant";
import { APLightbox } from "@/components";

export class QuizForm {
    sqid = '';
    name = '';
    student = '';
    city = '';
    school = '';
    class = '';
    grade = '';
    unit = '';
    level = '';
    question = [];
    currentQuestion = 0;
    total = 0;
    completeDate = '';
    accuracy = 0;
    active = '';
    token = window.localStorage.getItem('teacherToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkResultInfoController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherHomeworkResultInfoController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        const item = {
            sqid: this.$route.query.sqid,
            token: this.token,
        }
        const editData = await QuizModel.teGetResult(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            sqid: string;
            name: string;
            student: string;
            city: string;
            school: string;
            class: string;
            grade: string;
            unit: string;
            level: string;
            question: [];
            total: number;
            currentQuestion: number;
            completeDate: string;
            accuracy: number;
            active: string;
        };
        this.quizForm.sqid = item.sqid;
        this.quizForm.name = item.name;
        this.quizForm.student = item.student;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.grade = item.grade;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.question = item.question;
        this.quizForm.total = item.total;
        this.quizForm.currentQuestion = item.currentQuestion;
        this.quizForm.completeDate = item.completeDate;
        this.quizForm.accuracy = item.accuracy;
        this.quizForm.active = item.active;
    }

    private async validateAndSubmit() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item: { token: string, sqid: string, teacherVerify: response } = {
                token: this.token,
                sqid: this.quizForm.sqid,
                teacherVerify: {},
              }
        
              for (const [key, option] of Object.entries(this.quizForm.question)) {
                if(option['type'] == 3) {
                  item.teacherVerify[key] = {
                    qqid: option['qqid'],
                    verify: option['verify'],  
                  }
                }
              }
        
            const errNo = await QuizModel.teVerify(item);
            this.doubleClick = false;
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    TeCommon.logout();
                    break;
                case ErrorCode.Success:
                    this.$router.push("/teacher/quiz/result");
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}